define("discourse/plugins/post-read-email/discourse/connectors/user-preferences-emails-pref-email-settings/post-read-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      return Discourse.SiteSettings.post_read_email_enabled;
    },
    setupComponent(args, component) {
      if (args.model.get('custom_fields.mark_post_as_read_on_email') == 'f' || args.model.get('custom_fields.mark_post_as_read_on_email') == 'false') {
        args.model.set('custom_fields.mark_post_as_read_on_email', false);
      }
    }
  };
});