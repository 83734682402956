define("discourse/plugins/post-read-email/discourse/templates/connectors/user-preferences-emails-pref-email-settings/post-read-email", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{preference-checkbox
    id="post-read-email-preference"
    labelKey="post_read_emails.mark_post_as_read_on_email"
    checked=model.custom_fields.mark_post_as_read_on_email
  }}
  
  */
  {
    "id": "UViKYSVJ",
    "block": "[[[1,[28,[35,0],null,[[\"id\",\"labelKey\",\"checked\"],[\"post-read-email-preference\",\"post_read_emails.mark_post_as_read_on_email\",[30,0,[\"model\",\"custom_fields\",\"mark_post_as_read_on_email\"]]]]]],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/post-read-email/discourse/templates/connectors/user-preferences-emails-pref-email-settings/post-read-email.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/post-read-email/discourse/templates/connectors/user-preferences-emails-pref-email-settings/post-read-email.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});