define("discourse/plugins/post-read-email/discourse/initializers/post-read-email", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'post-read-email',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.22', api => {
        api.modifyClass('controller:preferences/emails', {
          actions: {
            save() {
              this.saveAttrNames.push('custom_fields');
              this._super();
            }
          }
        });
      });
    }
  };
});